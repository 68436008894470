/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;


/* merriweather-regular - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/merriweather-v30-latin_latin-ext-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* merriweather-italic - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/merriweather-v30-latin_latin-ext-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-regular - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/open-sans-v40-latin_latin-ext-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-italic - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/open-sans-v40-latin_latin-ext-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-600 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/open-sans-v40-latin_latin-ext-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-600italic - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('/fonts/open-sans-v40-latin_latin-ext-600italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}


.text-link {
  @apply text-blue-700 hover:underline hover:text-blue-800 decoration-1 underline-offset-2 decoration-blue-200 hover:decoration-blue-800;
}

.prose {
  @apply text-gray-800;
  @apply prose-a:decoration-1 prose-a:underline-offset-2 ;
  @apply prose-blockquote:font-normal prose-blockquote:not-italic prose-blockquote:text-current;
  @apply prose-a:font-normal prose-a:text-blue-600 hover:prose-a:underline hover:prose-a:text-blue-800;
  @apply prose-a:decoration-1 prose-a:underline-offset-2 prose-a:decoration-blue-200 hover:prose-a:decoration-blue-800;
  @apply prose-headings:font-normal prose-headings:font-serif;

}

.prose blockquote p:before,
.prose blockquote p:after {
  content: "";
}

.prose .paragraph-number {
  @apply absolute w-10 p-1 -ml-12 text-xs leading-5 text-right text-black no-underline rounded-sm hover:bg-gray-100;
}
